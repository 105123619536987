import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import Emphasis from "./emphasis"

const ArticleCard = ({ article, className }) => {
  return (
    <Link
      to={article.url}
      className={
        "h-96 flex flex-col rounded-lg shadow-lg overflow-hidden hover:scale-105 duration-200 " +
        className
      }
    >
      <div className="basis-3/5 relative">
        <GatsbyImage
          image={article.image}
          alt={article.image.description || ""}
          layout="fullWidth"
          objectPosition="center"
          objectFit="cover"
          className="!absolute !inset-0"
          placeholder="blurred"
        />
      </div>
      <div className="p-4 grow flex flex-col">
        <div className="grow">
          <Emphasis>{article.title}</Emphasis>
        </div>
        <div className="text-gray-600 italic">
          {article.minutesToRead} min read
        </div>
      </div>
    </Link>
  )
}

export default ArticleCard
